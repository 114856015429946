<template>
  <v-container fluid>
    <v-app-bar rounded class="distort">
      <v-app-bar-nav-icon
        @click="drawer = true"
        class="distort"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <div class="logo">
          <font-awesome-icon icon="quidditch" class="icon-align" />
          <span class="label"><b> Quidditch</b></span>
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="changeLights">
        <v-icon v-if="light">mdi-brightness-5</v-icon>
        <v-icon v-else>mdi-brightness-2</v-icon>
      </v-btn>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="distort">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list class="distort">
          <v-list-item v-for="(n, i) in navigation" :key="i" @click="() => {}">
            <v-list-item-title>
              {{ n.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-row>
      <v-col lg="2" class="distract">
        <div class="carder">
          <v-navigation-drawer
            floating
            color="transparent"
            width="100%"
            class="pt-1"
            height="95vh"
          >
            <div>
              <div class="logo justify-center">
                <font-awesome-icon icon="quidditch" class="icon-align" />
                <span class="label"><b> Quidditch</b></span>
              </div>
            </div>
            <v-divider class="my-2 px-2"></v-divider>
            <div class="flex justify-center">
              <v-avatar
                size="54"
                color="light-blue darken-1 white--text"
                class="mt-3"
              >
                <img :src="require('../../assets/users/users-1.svg')" alt="P" />
              </v-avatar>
            </div>
            <!-- <p class="text-center mt-3">Hi, Mr. Potter</p> -->
            <p class="text-center mt-3">
              Role :
              <v-chip class="ma-2" color="success" outlined>
                <v-icon left> mdi-server </v-icon>
                Super Admin
              </v-chip>
            </p>
            <v-list dense nav>
              <v-list-item
                v-for="item in navigation"
                :key="item.title"
                link
                color="blue darken-1"
                :to="item.path"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <template v-slot:append>
              <div class="pa-2">
                <div class="px-3">
                  <v-switch
                    label="Dark mode"
                    :value="dark"
                    @click="changeMode"
                    color="blue"
                  ></v-switch>
                </div>
              </div>
            </template>
          </v-navigation-drawer>
        </div>
      </v-col>
      <v-col lg="10" sm="12">
        <div v-for="(value, index) in navigation" :key="index" class="w-100">
          <div v-if="Route === value.name">
            <component :is="value.component" />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  data() {
    return {
      light: true,
      lights: "mdi-brightness-5",
      options: [
        { name: "Settings", to: "/Settings" },
        { name: "Logout", to: "/Logout" },
      ],
      navigation: [
        {
          path: "/dashboard/main",
          name: "main",
          title: "Home",
          icon: "mdi-view-dashboard",
          component: () => import("./main/index"),
        },
        {
          path: "/dashboard/merchants",
          name: "merchants",
          title: "Merchants",
          icon: "mdi-store",
          component: () => import("./merchants/index"),
        },
        {
          path: "/dashboard/users",
          name: "users",
          title: "Users",
          icon: "mdi-account",
          component: () => import("./users/index"),
        },
        {
          path: "/dashboard/tracker",
          name: "tracker",
          title: "Transaction checker",
          icon: "mdi-progress-check",
          component: () => import("./Tracker/index"),
        },
        {
          path: "/dashboard/settings/detail",
          name: "settings",
          title: "Settings",
          icon: "mdi-cogs",
          component: () => import("./Settings"),
        },
        { title: "Logout", icon: "mdi-logout", to: "/" },
      ],
      drawer: false,
      Route: this.$route.params.child,
    };
  },

  computed: {
    ...mapGetters({
      dark: "getDark",
    }),
  },

  watch: {
    $route: "updateRoute",
  },

  methods: {
    updateRoute() {
      this.Route = this.$route.params.child;
    },

    changeLights() {
      this.lights = this.light ? "mdi-brightness-5" : "mdi-brightness-2";
      this.light = !this.light;
    },

    changeMode() {
      this.$store.dispatch("switchDark");
    },

    getOut(i, v) {
      console.log(i, v);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
.logo {
  .icon-align {
    width: 19%;
  }
}
.distort {
  display: none;
}
.height {
  height: 90%;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.hello {
  display: flex;
  justify-content: center;
}
@media (max-width: 600px) {
  .distort {
    display: block;
  }
}
</style>
